//–––––––––––––––––––––
//  REM CONVERTER
//–––––––––––––––––––––

$text-base-size: 16px;

@function rem($size) {
  @return #{$size / $text-base-size}rem;
}

//–––––––––––––––––––––
//  RESPOND ABOVE
//–––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//–––––––––––––––––––––
//  RESPOND BELOW
//–––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//–––––––––––––––––––––
//  RESPOND BEETWEEN
//–––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//–––––––––––––––––––––
//  EXPANDED ABSOLUTE
//–––––––––––––––––––––

@mixin expanded-abs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//–––––––––––––––––––––
//  EXPANDED FIXED
//–––––––––––––––––––––

@mixin expanded-fix {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//–––––––––––––––––––––
//  COMPONENT SPACERS
//–––––––––––––––––––––

$resolution-range-bottom: map-get($breakpoints, xs);
$resolution-range-top: map-get($breakpoints, xxl);

@function get-linear-size($mobile-size, $desktop-size) {
  $a: ($desktop-size - $mobile-size) /
    ($resolution-range-top - $resolution-range-bottom);
  $b: -$a * $resolution-range-bottom;
  @return calc(#{$a} * 100vw + #{$b + $mobile-size});
}

@mixin v-spacing($field, $mobile-size, $desktop-size) {
  #{$field}: get-linear-size($mobile-size, $desktop-size);
  @include respond-above(xxl) {
    #{$field}: $desktop-size;
  }
  @include respond-below(s) {
    #{$field}: $mobile-size;
  }
}

// Example to include margin-bottom @include v-spacing(margin-bottom, 40px, 100px);

//–––––––––––––––––––––
//  PADDING WRAPPER
//–––––––––––––––––––––

$p-wp-mobile: 35px;
$p-wp-tablet: 105px;
$p-wp-desktop: 150px;

@mixin main-wrapper {
  margin-left: $p-wp-mobile;
  margin-right: $p-wp-mobile;
  @include respond-above(s) {
    margin-left: $p-wp-tablet;
    margin-right: $p-wp-tablet;
  }
  @include respond-above(l) {
    margin-left: $p-wp-desktop;
    margin-right: $p-wp-desktop;
  }
}

@mixin viewportHeight($property: height, $vh: 100) {
  #{$property}: (1vh * $vh);
  #{$property}: calc(var(--vh, 1vh) * #{$vh});
}

//–––––––––––––––––––––
//  IMAGE CROP
//–––––––––––––––––––––

@mixin allowCrop {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  &.focusOnTop {
    top: 0;
    transform: translate(-50%, 0%);
  }
  &.focusOnBottom {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
  }
}

//–––––––––––––––––––––
//  SECTION
//–––––––––––––––––––––

@mixin section {
  display: block;
  position: relative;
  width: 100%;
}

//–––––––––––––––––––––
//  MAX WIDTH
//–––––––––––––––––––––

@mixin maxWidth {
  display: block;
  position: relative;
  max-width: rem(1440px);
  width: 100%;
  margin: 0 auto;
}

//–––––––––––––––––––––
//  INNER WIDTH
//–––––––––––––––––––––

@mixin innerWidth {
  width: calc(100% - 90px);
  margin: 0 auto;
  text-align: center;
  @include respond-above(s) {
    width: calc(100% - 72px);
  }
  @include respond-above(m) {
    width: 80%;
  }
  @include respond-above(l) {
    width: 75%;
  }
}

//–––––––––––––––––––––
//  HIDDEN SCROLLBAR
//–––––––––––––––––––––

@mixin hidden-scrollbar {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

//–––––––––––––––––––––
//  ELLIPSIS BUTTON
//–––––––––––––––––––––

@mixin ellipsis-button {
  width: 90px;
  height: 45px;
  border: 1px solid transparent;
  border-radius: 100px / 50px;
  cursor: pointer;
}

//–––––––––––––––––––––––––––––––––
//  MANAGE THEME: BACKGROUND-COLOR
//–––––––––––––––––––––––––––––––––

@mixin theme-background-color {
  transition: $transition;
  [data-theme="dark"] & {
    background-color: $black;
  }
  [data-theme="light"] & {
    background-color: $white;
  }
}

//–––––––––––––––––––––
//  MANAGE THEME: COLOR
//–––––––––––––––––––––

@mixin theme-color {
  transition: $transition;
  [data-theme="dark"] & {
    color: $white;
  }
  [data-theme="light"] & {
    color: $black;
  }
}

//––––––––––––––––––––––––––––
//  MANAGE THEME: LINES COLOR
//––––––––––––––––––––––––––––

@mixin theme-lines-color {
  transition: $transition;
  [data-theme="dark"] & {
    background-color: $white;
  }
  [data-theme="light"] & {
    background-color: $black;
  }
}

//––––––––––––––––––––––––––––
//  MANAGE THEME: FILL COLOR
//––––––––––––––––––––––––––––

@mixin theme-fill-color {
  transition: $transition;
  [data-theme="dark"] & {
    fill: $white;
  }
  [data-theme="light"] & {
    fill: $black;
  }
}

//––––––––––––––––––––––––––––
//  MANAGE THEME: BORDER COLOR
//––––––––––––––––––––––––––––

@mixin theme-border-color {
  transition: $transition;
  [data-theme="dark"] & {
    border-color: $white;
  }
  [data-theme="light"] & {
    border-color: $black;
  }
}


//––––––––––––––––––––––––––––
//  TOP FIX FOR HOME
//––––––––––––––––––––––––––––

@mixin top-cmp-in-home {
   // This top is the same of the header element
   position: relative;
   top: -170px;
   @include respond-above(s) {
     top: -150px;
   }
   @include respond-above(xl) {
     top: -260px;
   }
}
