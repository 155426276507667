// accordion style

.accordion {
  @include theme-background-color;
  @include theme-color;
  margin-bottom: 30px;
}

.accordion__container {
  border-top: 1px solid;
}

// SINGLE ACCORDION

.accordion__single {
  cursor: pointer;
}

.accordion__content {
  @include t-about;
  border-bottom: 1px solid;
  cursor: default;
}

.accordion__clickable {
  @include t-title;
  padding: 20px;
  border-bottom: 1px solid;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: $transition;
}

.accordion__btn {
  display: block;
  position: relative;
  height: 18px;
  width: 18px;
  margin-right: 20px;

  span {
    @include theme-lines-color;
    display: block;
    position: absolute;
    top: 50%;
    height: 2px;
    width: 100%;
    opacity: 1;
    left: 0;
    transition: $transition;

    &:nth-child(1) {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  .accordion__clickable--active & {
    span:nth-child(1) {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}

// CONTENT EACH ACCORDION

.accordion__content {
  display: none;
  padding: 20px;
}
