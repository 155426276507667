*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  // cursor: none;
  min-height: 100vh;
  @include theme-background-color;
}

.main__wrapper{
  position:relative;
  display:block;
  min-height: 100vh;
  @include main-wrapper;
}