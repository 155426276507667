// cursor style

.cursorIn {
  display: none;
  @include respond-above(m) {
    @include theme-lines-color;
    display: block;
    position: fixed;
    top: -4px;
    left: -4px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    pointer-events: none;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 99999;
    -ms-transform: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.cursorOut {
  display: none;
  @include respond-above(m) {
    display: block;
    position: fixed;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    pointer-events: none;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 99999;
    -ms-transform: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.cursorSquare {
  @include theme-border-color;
  position: relative;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid;
  pointer-events: none;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99999;
  -ms-transform: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: center center;

  &.animation {
    width: 60px;
    height: 60px;
    -ms-transform: rotate(45deg) translate(-70.7%, 0%);
    -webkit-transform: rotate(45deg) translate(-70.7%, 0%);
    -moz-transform: rotate(45deg) translate(-70.7%, 0%);
    -o-transform: rotate(45deg) translate(-70.7%, 0%);
    transform: rotate(45deg) translate(-70.7%, 0%);
  }
}
