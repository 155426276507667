.projectInfo__wrapper {
  display: block;
  position: relative;
  width: 100%;

  @include respond-above(xl) {
    margin-bottom: 30px;
    overflow: hidden;
  }
}
//HEADER
.projectInfo__headerWrap {
  display: block;
  position: absolute;
  width: 100%;
  @include respond-above(s) {
    width: 100%;
  }
  @include respond-above(xl) {
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100%) translateY(0px);
  }
}

.projectInfo__headerText {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    @include theme-lines-color;
  }

  @include respond-above(s) {
  }
  @include respond-above(xl) {
    justify-content: flex-end;

    &:first-child {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        @include theme-lines-color;
      }
    }
  }
}

.projectInfo__headerText--name {
  height: 50px;
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    height: 75px;
  }
}

.projectInfo__headerText--role {
  height: 26px;
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    height: 60px;
  }
}

.projectInfo__headerText--year {
  height: 26px;
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    height: 40px;
  }
}

.projectInfo__text--name {
  @include t-title;
}

.projectInfo__text--role {
  @include t-description;
}

//CONTENT WRAPPER
.projectInfo__contentWrap {
  position: relative;
  display: flex;
  padding-top: 122px;

  @include respond-above(s) {
  }
  @include respond-above(xl) {
    padding-top: 0px;
    margin-left: 175px;
    height: 100%;
  }
}
//DESCRIPTION + TEAM WRAPPER
.projectInfo__infoWrap {
  display: block;
  width: 100%;

  @include respond-above(s) {
  }
  @include respond-above(xl) {
    width: 40%;
    padding: 0 20px;
  }
}

//TEAM INFO
.projectInfo__teamWrap {
  display: none;
  @include respond-above(xl) {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.projectInfo__descriptionButton {
  @include t-button;
  @include ellipsis-button;
  transition: $transition;
  margin-bottom: 30px;
  cursor: auto;

  [data-theme="dark"] & {
    border-color: $white;
  }

  [data-theme="light"] & {
    border-color: $black;
  }
}

.projectInfo__teamText {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px 0;

  @include respond-above(xl) {
    &:first-child {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        @include theme-lines-color;
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      @include theme-lines-color;
    }
  }
}

.projectInfo__team--name {
  @include t-projectTitle;
}

.projectInfo__team--role {
  @include t-projectText;
}

//TEAM MOBILE ELEMENT
.projectInfo__teamWrap--mobile {
  position: relative;
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 70px;
    @include theme-lines-color;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    @include theme-lines-color;
  }
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    display: none;
  }
}

//DESCRIPTION
.projectInfo__descriptionWrap {
  position: relative;
  display: block;
  width: 100%;
  @include respond-above(xl) {
    height: 300px;
    margin-bottom: 30px;
    padding-right: 30px;
    @include hidden-scrollbar;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      z-index: 1;
      [data-theme="dark"] & {
        background: rgb(19, 22, 20);
        background: linear-gradient(
          0deg,
          rgba(19, 22, 20, 0.8) 0%,
          rgba(19, 22, 20, 0) 100%
        );
      }
      [data-theme="light"] & {
        background: rgb(229, 229, 229);
        background: linear-gradient(
          0deg,
          rgba(229, 229, 229, 0.8) 0%,
          rgba(229, 229, 229, 0) 100%
        );
      }
    }
  }
}

.projectInfo__contentText {
  display: block;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  @include hidden-scrollbar;
}

.projectInfo__text--description {
  @include t-text;
}

//IMAGE header
.projectInfo__sliderWrap {
  position: relative;
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    width: 60%;
    overflow: hidden;
    object-fit: cover;
  }
}
.projectInfo__imageHeader {
  display: none;
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}

.projectInfo__videoHeader {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

//IMAGES
.projectInfo__imageWrap {
  width: 100%;
  margin-top: 20px;
  @include respond-above(s) {
  }
  @include respond-above(xl) {
    margin-top: 80px;
  }
}

.projectInfo__image {
  width: 100%;
  display: block;
  position: relative;
}

.projectInfo__video {
  display: block;
  width: 100%;
  @include respond-above(m) {
    height: 100vh;
  }
}
//VIDEO TEST

.embed-container {
  margin: 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
