@font-face {
  font-family: "SaolDisplay";
  src: 
    // url("../fonts/SaolDisplay/SaolDisplay-400-Italic"),
    url("../fonts/SaolDisplay/SaolDisplay-400-Italic?#iefix") format("embedded-opentype"),
    url("../fonts/SaolDisplay/SaolDisplay-400-Italic.woff2") format("woff2"),
    url("../fonts/SaolDisplay/SaolDisplay-400-Italic.woff") format("woff"),
    url("../fonts/SaolDisplay/SaolDisplay-400-Italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SohneLight";
  src: 
    // url("../fonts/Sohne/Sohne-300"),
    url("../fonts/Sohne/Sohne-300?#iefix") format("embedded-opentype"),
    url("../fonts/Sohne/Sohne-300.woff2") format("woff2"),
    url("../fonts/Sohne/Sohne-300.woff") format("woff"),
    url("../fonts/Sohne/Sohne-300.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SohneNormal";
  src: 
    // url("../fonts/Sohne/Sohne-400"),
    url("../fonts/Sohne/Sohne-400?#iefix") format("embedded-opentype"),
    url("../fonts/Sohne/Sohne-400.woff2") format("woff2"),
    url("../fonts/Sohne/Sohne-400.woff") format("woff"),
    url("../fonts/Sohne/Sohne-400.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SohneMedium";
  src: 
    // url("../fonts/Sohne/Sohne-500"),
    url("../fonts/Sohne/Sohne-500?#iefix") format("embedded-opentype"),
    url("../fonts/Sohne/Sohne-500.woff2") format("woff2"),
    url("../fonts/Sohne/Sohne-500.woff") format("woff"),
    url("../fonts/Sohne/Sohne-500.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SohneSemiBold";
  src: 
    // url("../fonts/Sohne/Sohne-600"),
    url("../fonts/Sohne/Sohne-600?#iefix") format("embedded-opentype"),
    url("../fonts/Sohne/Sohne-600.woff2") format("woff2"),
    url("../fonts/Sohne/Sohne-600.woff") format("woff"),
    url("../fonts/Sohne/Sohne-600.ttf") format("truetype");
  font-display: swap;
}

// FONT VARIABLES

$SaolDisplay: "SaolDisplay", Arial, sans-serif;

$SohneLight: "SohneLight", Arial, sans-serif;

$SohneNormal: "SohneNormal", Arial, sans-serif;

$SohneMedium: "SohneMedium", Arial, sans-serif;

$SohneSemiBold: "SohneSemiBold", Arial, sans-serif;

// FONTS

@mixin fontSaolDisplay {
  font-family: $SaolDisplay;
  font-style: italic;
  font-weight: 400;
  @include theme-color;
}

@mixin fontSohneLight {
  font-family: $SohneLight;
  font-style: normal;
  font-weight: 300;
  @include theme-color;
}

@mixin fontSohneNormal {
  font-family: $SohneNormal;
  font-style: normal;
  font-weight: 400;
  @include theme-color;
}

@mixin fontSohneMedium {
  font-family: $SohneMedium;
  font-style: normal;
  font-weight: 500;
  @include theme-color;
}

@mixin fontSohneSemiBold {
  font-family: $SohneSemiBold;
  font-style: normal;
  font-weight: 600;
  @include theme-color;
}

// TEXT STYLE

@mixin t-text-mobile {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.04em;
}

@mixin t-text-tablet {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

@mixin t-text-desktop {
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

@mixin t-text {
  @include fontSohneLight;

  @include t-text-mobile;
  @include respond-above(s) {
    @include t-text-tablet;
  }
  @include respond-above(xl) {
    @include t-text-desktop;
  }
}

@mixin t-menu-mobile {
  font-size: 43px;
  line-height: 52px;
  letter-spacing: -0.02em;
}

@mixin t-menu-tablet {
  font-size: 43px;
  line-height: 52px;
  letter-spacing: -0.02em;
}

@mixin t-menu-desktop {
  font-size: 21px;
  line-height: 25px;
  letter-spacing: -0.02em;
}

@mixin t-menu {
  @include fontSohneLight;

  @include t-menu-mobile;
  @include respond-above(s) {
    @include t-menu-tablet;
  }
  @include respond-above(xl) {
    @include t-menu-desktop;
  }
}

@mixin t-title-mobile {
  font-size: 24px;
  line-height: 19px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

@mixin t-title-tablet {
  text-transform: uppercase;
  font-size: 28px;
  line-height: 22px;
  letter-spacing: -0.03em;
}

@mixin t-title-desktop {
  text-transform: uppercase;
  font-size: 48px;
  line-height: 37px;
  letter-spacing: -0.03em;
}

@mixin t-title {
  @include fontSohneLight;

  @include t-title-mobile;
  @include respond-above(s) {
    @include t-title-tablet;
  }
  @include respond-above(xl) {
    @include t-title-desktop;
  }
}

@mixin t-description-mobile {
  font-size: 12px;
  line-height: 9px;
  text-transform: uppercase;
}

@mixin t-description-tablet {
  text-transform: uppercase;
  font-size: 21px;
  line-height: 16px;
}

@mixin t-description-desktop {
  text-transform: uppercase;
  font-size: 21px;
  line-height: 16px;
}

@mixin t-description {
  @include fontSohneLight;

  @include t-description-mobile;
  @include respond-above(s) {
    @include t-description-tablet;
  }
  @include respond-above(xl) {
    @include t-description-desktop;
  }
}

@mixin t-about-mobile {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
}

@mixin t-about-tablet {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
}

@mixin t-about-desktop {
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.04em;
}

@mixin t-about {
  @include fontSohneMedium;

  @include t-about-mobile;
  @include respond-above(s) {
    @include t-about-tablet;
  }
  @include respond-above(xl) {
    @include t-about-desktop;
  }
}

@mixin t-button-mobile {
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.03em;
}

@mixin t-button-tablet {
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.03em;
}

@mixin t-button-desktop {
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.03em;
}

@mixin t-button {
  @include fontSaolDisplay;

  @include t-button-mobile;
  @include respond-above(s) {
    @include t-button-tablet;
  }
  @include respond-above(xl) {
    @include t-button-desktop;
  }
}

//FOOTER TEXT

@mixin t-footerText-mobile {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
}

@mixin t-footerText-tablet {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.04em;
}

@mixin t-footerText-desktop {
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.04em;
}

@mixin t-footerText {
  @include fontSohneMedium;

  @include t-footerText-mobile;
  @include respond-above(s) {
    @include t-footerText-tablet;
  }
  @include respond-above(xl) {
    @include t-footerText-desktop;
  }
}

@mixin t-footerContact-mobile {
  font-size: 21px;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-decoration: underline;
}

@mixin t-footerContact-tablet {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.04em;
  text-decoration: underline;
}

@mixin t-footerContact-desktop {
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-decoration: underline;
}

@mixin t-footerContact {
  @include fontSohneLight;

  @include t-footerContact-mobile;
  @include respond-above(s) {
    @include t-footerContact-tablet;
  }
  @include respond-above(xl) {
    @include t-footerContact-desktop;
  }
}


@mixin t-projectText-mobile {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.02em;
  
}

@mixin t-projectText-tablet {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.04em;
  
}

@mixin t-projectText-desktop {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  
}

@mixin t-projectText {
  @include fontSohneLight;

  @include t-projectText-mobile;
  @include respond-above(s) {
    @include t-projectText-tablet;
  }
  @include respond-above(xl) {
    @include t-projectText-desktop;
  }
}

@mixin t-projectTitle {
  @include fontSohneSemiBold;

  @include t-projectText-mobile;
  @include respond-above(s) {
    @include t-projectText-tablet;
  }
  @include respond-above(xl) {
    @include t-projectText-desktop;
  }
}