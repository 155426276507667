// loader styles

.loader {
  position: fixed;
  width: 100vw;
  height: 200vh;
  pointer-events: none;
  background-color: $black;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
}

.is-transitioning {
  pointer-events: none;
  cursor: none;
}