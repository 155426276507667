// menu style

.menu {
  display: block;
  position: relative;
  z-index: 9;
}

.menu__hamburger {
  width: 40px;
  height: 18px;
  position: relative;
  background: transparent;
  cursor: pointer;
  border: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  margin-bottom: 20px;
  z-index: 10;

  span {
    @include theme-lines-color;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: $transition;
  }

  span:nth-child(1) {
    top: 1px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 9px;
  }

  span:nth-child(4) {
    top: 17px;
  }

  .menu--open & {
    span:nth-child(1) {
      transition: 0.1s ease-in-out;
      top: 18px;
      width: 0%;
      opacity: 0;
      left: 50%;
    }

    span:nth-child(2) {
      width: 50%;
      -webkit-transform: rotate(52deg);
      -moz-transform: rotate(52deg);
      -o-transform: rotate(52deg);
      transform: rotate(52deg);
    }

    span:nth-child(3) {
      width: 50%;
      -webkit-transform: rotate(-52deg);
      -moz-transform: rotate(-52deg);
      -o-transform: rotate(-52deg);
      transform: rotate(-52deg);
    }

    span:nth-child(4) {
      transition: 0.1s ease-in-out;
      top: 18px;
      opacity: 0;
      width: 0%;
      left: 50%;
    }
  }
}

.menu__content {
  @include theme-background-color;
  width: 60vw;
  min-height: 3000vh;
  height: calc(100vh + 60px);
  position: absolute;
  top: -60px;
  left: -$p-wp-mobile;
  transform: translate(-100%);
  transition: $transition;
  @include respond-above(s) {
    left: -$p-wp-tablet;
  }
  @include respond-above(l) {
    left: -$p-wp-desktop;
    width: 290px;
    min-height: 20vh;
    height: 20vh;
    
  }

  .menu--open & {
    transform: translateX(0%);
  }
}

.menu__list {
  height: 100%;
  padding: $p-wp-tablet $p-wp-mobile;
  @include respond-above(s) {
    padding: $p-wp-tablet;
  }
  @include respond-above(l) {
    padding: $p-wp-tablet $p-wp-desktop;
  }
}

.menu__text {
  @include t-menu; 
  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }
}
