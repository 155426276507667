// banner style

.banner {
  // This top is the same of the header element
  @include top-cmp-in-home;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 430px;

  @include respond-above(s) {
    height: 50vh;
  }
  @include respond-above(m) {
    height: 50vh;
  }
  @include respond-above(l) {
    height: 100vh;
  }
}

// LOGO

.banner__logo {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  .svgLogo {
    width: 240px;
    position: absolute;
    left: 8px;
    top: 177px;

    @include respond-above(s) {
      width: 65%;
      height: 75%;
      top: 58%;
      transform: translateY(-50%);
    }
    
    @include respond-above(l) {
      width: 75%;
      top: 58%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

// PROFILE

.banner__profile {
  width: 130px; 
  position: absolute;
  z-index: 1;
  top: 26%;
  right: 0;
  transform: translate(0);

  &:after {
    content: "";
    @include expanded-abs;
    width: 100%;
    height: 100%;
    [data-theme="dark"] & {
      background-color: rgba(19, 22, 20, 0.5);
    }
    [data-theme="light"] & {
      background-color: rgba(229, 229, 229, 0.5);
    }
  }

  @include respond-above(s) {
    width: 230px;
    top: 8%;
    left: 45%;
    right: auto;
  }

  @include respond-above(m) {
    width: 360px;
    top: 8%;
    left: 35%;
  }

  @include respond-above(xl) {
    width: 400px;
    top: 8%;
    left: 38%;
  }
}

.banner__img {
  display: block;
  width: 100%;
  height: 100%;
}

// WHOAMI

.banner__whoami {
  position: absolute;
  z-index: 1;
  bottom: 0%;
  right: 0%;

  @include respond-above(s) {
    bottom: 14%;
    right: 17%;
  }
  
  @include respond-above(m) {
    bottom: 17%;
    right: 24%;
  }

  @include respond-above(l) {
    top: 50%;
    right: 8%;
  }
}

.banner__text {
  @include t-text;
}
