// footer style

.footer {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: calc(-170px + 30px);
  @include respond-above(s) {
    margin-bottom: calc(-150px + 30px);
  }
  @include respond-above(xl) {
    margin-bottom: calc(-260px + 30px);
  }

  // This top is the same of the header element
  @include top-cmp-in-home;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0px;
    @include theme-lines-color;
    @include respond-above(s) {
      height: 1px;
    }
    @include respond-above(xl) {
      left: 140px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    @include theme-lines-color;
    @include respond-above(s) {
      height: 1px;
    }
    @include respond-above(xl) {
      left: 140px;
    }
  }
}

.footer__content {
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  @include respond-above(s) {
    flex-direction: row;
    padding: 20px 0 20px 50px;
  }
  @include respond-above(xl) {
    flex-direction: row;
    padding: 50px 0 50px 140px;
  }
}

.footer__component {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  @include respond-above(s) {
    width: 50%;
    margin-bottom: 0px;
  }
}

.footer__contacts {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @include respond-above(s) {
    width: 50%;
    margin: 0 auto;
    justify-content: center;
  }
}

.footer__text {
  @include t-footerText;
}

.footer__contact {
  @include t-footerContact;
  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }
}
 