// next-prev style

.nextPrev {
  margin-bottom: 30px;
}

.nextPrev__hidden {
  display: none;
}

.nextPrev__wrapper {
  display: block;
  position: relative;
  width: 100%;
  @include respond-above(xl) {
    margin-top: 60px;
  }

  &::before {
    @include respond-above(l) {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 0;
      @include theme-lines-color;
    }
  }
}

.nextPrev__imageWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  @include respond-above(s) {
    padding-top: 60px;
  }
  @include respond-above(xl) {
    padding-top: 90px;
  }
}
.nextPrev__contentImg {
  width: 50%;
  &:first-child {
    padding-right: 10px;
  }
  &:nth-child(2) {
    padding-left: 10px;
  }
}
.nextPrev__img {
  display: block;
  width: 100%;
}
.nextPrev__buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 70px;
  margin-top: 20px;
  @include respond-above(s) {
    margin-top: 40px;
  }
  @include respond-above(xl) {
    margin-top: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    @include theme-lines-color;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    @include theme-lines-color;
  }
}

.nextPrev__text {
  @include t-title;
}
