.svgLogo{
  path{
    @include theme-fill-color;
  }
  svg{
    width:99px;
    height:55px;
    @include respond-above(s) {
      width: 88px;
      height:44px;
    }
    @include respond-above(xl) {
      width:140px;
      height:78px;
    }
  }
  
}