// theme style

.theme {
  position: absolute;
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-100%) translateY(0);
  top: 120px;
  bottom: auto;
  left: 0;
  right: auto;

  // @include respond-above(s) {
  //   transform: rotate(-90deg) translateX(-100%) translateY(0);
  //   top: 120px;
  //   bottom: auto;
  //   left: 0;
  //   right: auto;
  // }
  
  @include respond-above(s) {
    transform: rotate(-90deg) translateX(-100%) translateY(calc(210px - 45px));
    top: 120px;
    bottom: auto;
    left: auto;
    right: 0;
  }
}

.theme__wrapper {
  display: flex;
  justify-content: flex-start;
}

.theme__btn {
  @include t-button;
  @include ellipsis-button;

  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }

  &:first-child {
    margin-right: 70px;

    @include respond-above(s) {
      margin-right: 30px;
    }
  }
}

.theme__btn--dark {
  transition: $transition;
  [data-theme="dark"] & {
    border-color: $white;
  }
}

.theme__btn--light {
  transition: $transition;
  [data-theme="light"] & {
    border-color: $black;
  }
}

.theme__text {
  margin-bottom: 2px;
}
