// grid-projects style

.gridProjects {
  margin-bottom: 30px;
}

.gridProjects__section {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  &.gridProjects__section--reverse {
    flex-direction: row-reverse;
  }
}

.gridProjects__column--left {
  .gridProjects__section--double & {
    width: 60%;
    .gridItem {
      padding-bottom: 54.678%;
    }
  }
  .gridProjects__section--triple & {
    width: 66.7%;
    .gridItem {
      padding-bottom: 100%;
    }
  }
  .gridProjects__section--quadruple & {
    width: 60%;
    .gridItem:nth-child(1) {
      padding-bottom: 54.678%;
    }
    .gridItem:nth-child(2) {
      padding-bottom: 109.064%;
    }
  }
}

.gridProjects__column--right {
  .gridProjects__section--double & {
    width: 40%;
    .gridItem {
      padding-bottom: 82.017%;
    }
  }
  .gridProjects__section--triple & {
    width: 33.3%;
    .gridItem {
      padding-bottom: 100%;
    }
  }
  .gridProjects__section--quadruple & {
    width: 40%;
    .gridItem:nth-child(1) {
      padding-bottom: 163.596%;
    }
    .gridItem:nth-child(2) {
      padding-bottom: 82.017%;
    }
  }
}