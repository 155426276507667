// grid-home style

.gridHome {
  display: block;

  @include top-cmp-in-home;
}

.gridHome__selectedProjects {
  display: none;

  @include respond-above(s) {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    overflow: hidden;
    height: 60px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 0;
      @include theme-lines-color;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      @include theme-lines-color;
    }
  }

  @include respond-above(xl) {
    padding-left: 140px;
  }
}

.gridHome__selectedProjectsText {
  @include t-button;
}

//PROJECTS GRID

.gridHome__wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;

  @include respond-above(s) {
    height: 100%;
    margin-top: 30px;
  }

  @include respond-above(xl) {
    margin-top: 60px;
    padding-left: 140px;
  }
}

//HEADER

.gridHome__headerWrap {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
  border-top: 1px solid;
  @include theme-border-color;

  @include respond-above(s) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    left: -80px;
    position: absolute;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100%) translateY(0px);
  }

  @include respond-above(xl) {
    left: -175px;
  }
}

.gridHome__headerText {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include respond-above(s) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      @include theme-lines-color;
    }
  }
}

.gridHome__wrapperRole {
  display: flex;
  justify-content: flex-start;
  @include respond-above(s) {
    display: block;
  }
}

.gridHome__headerText--name {
  @include respond-above(s) {
    height: 31px;
  }

  @include respond-above(xl) {
    height: 75px;
  }
}

.gridHome__headerText--role {
  text-align: right;

  @include respond-above(s) {
    text-align: left;
    height: 31px;
  }
  @include respond-above(xl) {
    height: 60px;
  }
}

.gridHome__headerText--year {
  padding-left: 12px;
  @include respond-above(s) {
    padding-left: 0px;
    height: 20px;
  }

  @include respond-above(xl) {
    height: 40px;
  }
}

.gridHome__text--name {
  @include t-title;
}

.gridHome__text--role {
  @include t-description;
}

// CONTENT WRAPPER

.gridHome__contentWrap {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  @include respond-above(s) {
    flex-direction: row;
    padding-left: 80px;
  }

  @include respond-above(xl) {
    padding-left: 175px;
  }
}

// DESCRIPTION

.gridHome__descriptionWrap {
  display: block;

  @include respond-above(s) {
    display: block;
    position: relative;
    width: 40%;
    padding-right: 30px;
    padding-left: 15px;
  }

  @include respond-above(xl) {
    padding-left: 20px;
  }
}

.gridHome__text--description {
  display: none;
  @include t-text;

  @include respond-above(s) {
    display: block;
  }
}

.gridHome__descriptionButton {
  @include t-button;
  @include ellipsis-button;
  @include theme-border-color;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }
}

//SLIDER

.gridHome__sliderWrap {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 66.666%;
    z-index: -1;
  }

  @include respond-above(s) {
    width: 60%;
  }
}

//SPLIDE

.splide {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  position: absolute;
}

.splide__pagination {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 !important;
  right: auto !important;
  left: 10px !important;
  bottom: 10px !important;

  @include respond-above(s) {
    left: -22px !important;
    bottom: 0 !important;
  }

  @include respond-above(xl) {
    left: -30px !important;
  }
}

.splide__pagination li {
  display: flex;
}

.splide__image {
  height: 100% !important;
}

.splide__pagination__page {
  @include theme-background-color;
  @include theme-border-color;
  margin-top: 6px;
  margin-bottom: 0px;
  border: 1px solid;
  opacity: 1;
  height: 12px;
  width: 12px;

  @include respond-above(xl) {
    height: 14px;
    width: 14px;
  }

  &.is-active {
    @include theme-lines-color;
    transform: scale(1);
  }
}
// JS EFFECT

.j-scroll {
  opacity: 0;
  transition: all 1s ease-in-out;
  &.scrolled{
    opacity: 1;
  }
}
 
