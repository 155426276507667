// waiting styles

.waiting {
  @include expanded-fix;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  z-index: 999;
  transition: $transition-loader-waiting;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;

  .svgLogo {
    transition: $transition-loader-waiting;
    opacity: 0;
    svg {
      width: 25vw !important;
      height: auto !important;
    }
    path {
      fill: $white !important;
    }
  }

  &.fadeIn {
    .svgLogo {
      opacity: 1;
      path {
        fill: $white !important;
      }
    }
  }

  &.removed {
    transform: translateY(100vh);
  }
}

.waiting__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
