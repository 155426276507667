// grid-item style

.gridItem {
  display: block;
  position: relative;
  overflow: hidden;
 
  @include respond-above(l) {
    &:hover {
      .gridItem__contentInfo {
        transform: translateX(0);
      }
      .gridItem__img {
        filter: grayscale(1);
        //filter: invert(1);
      }
    }
  }
  
}

.gridItem__area {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
  @include respond-above(s) {
    padding: 10px;
  }
  @include respond-above(m) {
    padding: 10px;
  }
}

.gridItem__info {
  overflow: hidden;
  height: 100%;
}

// IMAGES GRID STYLE

.gridItem__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  padding: 5px;
  transition: $transition;
  @include respond-above(s) {
    padding: 10px;
  }
  @include respond-above(m) {
    padding: 10px;
  }
}

// INFO GRID STYLE

.gridItem__contentInfo {
  display: block;
  position: relative;
  height: 100%;
  width: 300px;
  background: $white;
  opacity: 0.8;
  transform: translateX(calc(-100% - 1px));
  transition: $transition;
  z-index: 1;
}

// CONTENT GRID STYLE

.gridItem__desc {
  display: block;
  position: relative;
  padding: 30px;
  height: 100%;
}

.gridItem__wrapTitle {
  display: block;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
  }
}
 
.gridItem__wrapText {
  display: block;
  position: relative;
  padding: 24px 0;
  height: 50%;
  
}

.gridItem__contentText {
  display: block;
  position: relative;
  height: 100%;
  //overflow-y: scroll;
  @include hidden-scrollbar;
}

.gridItem__title {
  @include t-title;
  color: $black !important;
}

.gridItem__text {
  @include t-text;
  font-size: 1.4vw !important;
  color: $black !important;
}

.gridItem__wrapButton {
  height: 30%;
}

.gridItem__button {
  @include t-button;
  @include ellipsis-button;
  color: $black !important;
  margin-top: 24px;
  border-color: $black;
  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }
}
