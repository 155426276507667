// contact style

.contact {
  position: relative;
  display: block;
}

.contact__wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
  @include respond-above(xl) {
    flex-direction: row;
  }
}

// INFO

.contact__info {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px;
  
  @include respond-above(xl) {
    width: 30%;
    padding-bottom: 143px; // 58 + 45 + 40
  }
}

.contact__infoContent {
  display: flex;
  flex-direction: column;
}

.contact__link {
  color: $black;
  @include t-footerContact;
  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }
}

// FORM

.contact__form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 18px 0 58px;

  @include respond-above(xl) {
    width: 70%;
  }
}

.contact__input {
  margin-bottom: 18px;
  padding: 10px;
  @include t-footerContact;
  text-decoration: none !important;

  [data-theme="light"] & {
    border: 1px solid $black;
  }
  [data-theme="dark"] & {
    border: 1px solid $white;
  }
}

.contact__textarea {
  padding: 10px;
  @include t-footerContact;
  text-decoration: none !important;

  [data-theme="light"] & {
    border: 1px solid $black;
  }
  [data-theme="dark"] & {
    border: 1px solid $white;
  }
}

.contact__submit {
  @include t-button;
  @include ellipsis-button;
  margin-top: 40px;
  transition: $transition;

  [data-theme="dark"] & {
    border-color: $white;
  }

  [data-theme="light"] & {
    border-color: $black;
  }
  
  transition: $transition;
  &:hover {
    transform: scale(1.1);
  }
}
