//–––––––––––––––––––––
//  BREAKPOINTS
//–––––––––––––––––––––

$breakpoints: (
  xxs_minus: 374px,
  xxs: 375px,
  xxs_plus: 376px,
  xs_minus: 479px,
  xs: 480px,
  xs_plus: 481px,
  s_minus: 767px,
  s: 768px,
  s_plus: 769px,
  m_minus: 1023px,
  m: 1024px,
  m_plus: 1025px,
  l_minus: 1279px,
  l: 1280px,
  l_plus: 1281px,
  xl_minus: 1439px,
  xl: 1440px,
  xl_plus: 1441px,
  xxl_minus: 1599px,
  xxl: 1600px,
  xxl_plus: 1601px
);

//–––––––––––––––––––––
//  TRANSITIONS
//–––––––––––––––––––––

$curve-custom: cubic-bezier(0.09, 0.72, 0.76, 1.01);
$transition: all 0.6s $curve-custom;
$transition2: all 0.4s $curve-custom;
$transition-ease: all 0.4s ease;
$transition-loader-waiting: all 0.6s ease;


//–––––––––––––––––––––
//  SPACERS
//–––––––––––––––––––––

$spacers-xxs: 0.25rem; // 4px
$spacers-xs: 0.5rem; // 8px
$spacers-s: 1rem; // 16px
$spacers-m: 1.5rem; // 24px
$spacers-l: 2rem; // 32px
$spacers-xl: 2.5rem; // 40px
$spacers-xxl: 3.5rem; // 56px
$spacers-z: 4.5rem; // 72px
$spacers-xz: 5.5rem; // 88px
$spacers-xxz: 6.5rem; // 104px

//–––––––––––––––––––––
//  COLORS
//–––––––––––––––––––––

$white: #E5E5E5;
$black: #131614;


//–––––––––––––––––––––
//  HEADER DIMENSION
//–––––––––––––––––––––

$height-header-mobile: 70px; // > 0
$height-header-tablet: 70px; // > 768
$height-header-desktop: 90px; // > 1024
$height-header-desktop-large: 110px; // > 1440